<template>
  <div fp-complete :class="['field-wrapper', {'success': isSuccess}]">
    <FontIcon :name="icon" />
    <h3>{{ title }}</h3>
    <p class="desc" v-html="desc"></p>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'FpComplete',
  components: { FontIcon },
  props: {
    structure: Specific,
  },
  computed: {
    isSuccess() {
      return this.structure.success;
    },
    icon() {
      return this.isSuccess ? 'check-circle-regular' : 'exclamation-circle-regular';
    },
    desc() {
      return this.isSuccess ? this.$t('forgotPassword.description.successDesc') : this.structure.errorMsg || this.$t('VERIFICATIONTOKEN_NOT_FOUND');
    },
    title() {
      if (this.structure.errorTitle && !this.isSuccess) return this.$t(this.structure.errorTitle);
      else return this.isSuccess ? this.$t('forgotPassword.title.success') : this.$t('forgotPassword.title.error');
    },
    btnName() {
      return this.isSuccess ? 'playNow' : 'tryAgain';
    }
  },
  methods: {
    action() {
      if (this.isSuccess) close(this);
      else this.routeName('FpInfo');
    }
  },
  mounted() {
    this.structure.title = null;
    this.structure.desc = null;
    this.structure.back = false;
  },
  beforeDestroy() {
    this.$services.cookie.removeGpToken();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-complete] { .tc(); .flex();.flex-dc(); .justify-center();
  [font-icon] { .block(); .fs(60); .c(@c-red); }
  h3 { .regular(); .fs(28, 34); .m(24, 0, 12); }
  &.success {
    [font-icon] { .c(#1DB77D); }
  }
}
</style>